@import 'typography';
@import '../../projects/leap/src/environments/environment.generated.scss';

/**
 * Usage: font-size: text-size(text-settings, md)
 * See _typography.scss for all available font settings
 */
@function font-size($size: normal) {
    @return map-get(map-get($text-settings, $size), 'font-size');
}

/**
 * Usage: font-family: font-family(text-settings, title)
 * See _typography.scss for all available font settings
 */
@function font-family($family: $body-font) {
    @return map-get(map-get($text-settings, $family), 'font-family');
}

/**
 * Usage: font-family: font-weight(text-settings, 400)
 * See _typography.scss for all available font settings
 */
@function font-weight($weight: 400) {
    @return map-get(map-get($text-settings, $weight), 'font-weight');
}

@function btn-class($class) {
    @if ($organization == 'dmi' and $class == 'btn-secondary') {
        @return 'btn-secondary-outlined';
    } @else {
        @return $class;
    }
}
