// ngb-dropdown
.dropdown,
.dropup {
    min-width: 0;
    position: relative;
    display: inline-block;
    border-bottom: none;

    .dropdown-toggle {
        &:first-child {
            @include font-properties(xs);
            display: flex;
            justify-content: space-between;
        }

        &:after {
            display: none;
        }
    }

    .dropdown-btn {
        @include font-properties(xs);
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;

        &.btn-transparent {
            border: none;
            background-color: transparent;
            color: var(--text-primary);

            .fa-solid {
                color: var(--text-primary);
                position: relative;
                top: 0;
                right: 0;
            }
        }

        &.btn-primary {
            border: none;
        }
    }

    .dropdown-menu {
        position: absolute;
        border-radius: 4px;
        border: 1px solid var(--white);
        background-color: var(--white);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        min-width: 5.625rem;
        width: 100%;
        padding: 0;

        .list-unstyled {
            border: none;
            border-radius: 4px;
            margin-bottom: 0;
            background-color: var(--white);

            .dropdown-item {
                @include font-properties(xs);
                color: var(--text-primary);
                border-bottom: 0;
                padding: 10px;
                transition: all ease-in-out 300ms;
                border-radius: 4px;

                &:hover {
                    background-color: var(--grey-100);
                }

                &.disabled {
                    color: var(--grey-300);
                }
            }
        }

        &.right {
            right: 0;
            left: auto;
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--text-secondary);
        }
    }
}

.show > .dropdown-toggle {
    &.btn-primary {
        background-color: var(--branding-secondary);
        color: var(--white);
    }

    &.btn-secondary {
        background-color: var(--branding-secondary);
        border-color: var(--branding-secondary);
        color: var(--white);
    }
}
