/* Rounded elements */
.chat-bubble {
    box-shadow: var(--subtle-shadow);
    padding: 15px 28px;

    &.history {
        padding: 9px 16px 9px 10px;
        border-radius: 0 30px 30px 10px;
        background-color: var(--white);
    }

    &.question {
        border-radius: 25px 0 20px 25px;
        background-color: var(--branding-secondary-100);
    }

    &.answer {
        border-radius: 0 25px 25px 25px;
        background-color: var(--branding-primary-100);
    }
}
