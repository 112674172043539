@import '../base/mixins';

.btn {
    @include font-properties(sm);
    border-radius: 4px;
    border-width: 0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    line-height: 14px;
    letter-spacing: 0.01em;
    padding: 0.5rem 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 6px;

    &.no-hover {
        &:hover {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
        }
    }
}

button,
.btn {
    &.disabled,
    &:disabled {
        cursor: not-allowed;
        opacity: 1;
    }
}

button:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.dropdown-toggle:focus {
    outline: none !important;
    box-shadow: none;
}

// sizing
.btn-xxs {
    @include font-properties(xxs);
    border-radius: 4px;
    line-height: 13px;
    min-width: 95px;
    padding: 0.3rem 0.5rem;
}

.btn-xs {
    @include font-properties(xs);
}

.btn-sm {
    padding: 0.75rem 1.875rem;
    min-width: 140px;
}

.btn-normal {
    @include font-properties(normal);
    border-radius: 8px;
    line-height: 19px;
    padding: 1rem 1.875rem;
    min-width: 140px;

    &.btn-long {
        min-width: 240px;
    }
}

.btn-md {
    @include font-properties(md);
}

.btn-lg {
    @include font-properties(lg);
}

.btn-xl {
    @include font-properties(xl);
}

// theming
.btn-primary {
    background-color: var(--branding-primary-900);
    color: var(--white);

    .icon-wrapper {
        color: var(--branding-primary-900);
    }

    &:focus {
        background-color: var(--branding-primary-900);
        color: var(--white);
        box-shadow: 0px 0px 0px 3px var(--branding-primary-200) !important;
    }

    &:hover {
        background-color: var(--branding-secondary);
        color: var(--white);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        background-color: var(--branding-secondary);
        color: var(--white);
    }

    &.disabled,
    &:disabled {
        background-color: var(--branding-primary-200);
        color: var(--grey-800);
    }

    &.dropdown-toggle:focus {
        box-shadow: none !important;
    }
}

.btn-secondary {
    background-color: var(--branding-secondary-900);
    color: var(--white);

    .icon-wrapper {
        color: var(--branding-secondary-900);
    }

    &:focus {
        background-color: var(--branding-secondary-900);
        color: var(--white);
        box-shadow: 0px 0px 0px 3px var(--branding-secondary-200) !important;
    }

    &:hover {
        background-color: var(--branding-primary-700);
        color: var(--white);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        background-color: var(--branding-primary-900);
        color: var(--white);
    }

    &.disabled,
    &:disabled {
        background-color: var(--branding-primary-200);
        color: var(--grey-800);
    }

    &.dropdown-toggle:focus {
        box-shadow: none !important;
    }
}

.btn-secondary-outlined {
    background-color: var(--white);
    border: 1px solid var(--branding-primary-500);
    color: var(--branding-primary);

    .icon-wrapper {
        color: var(--branding-secondary);
    }

    &:focus {
        background-color: var(--white);
        border-color: var(--branding-primary-500);
        color: var(--branding-primary);
        box-shadow: 0px 0px 0px 3px var(--branding-primary-200) !important;
    }

    &:hover {
        background-color: var(--branding-secondary);
        border-color: var(--branding-secondary);
        color: var(--white);
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
        background-color: var(--branding-secondary);
        border-color: var(--branding-secondary);
        color: var(--white);
    }

    &.disabled,
    &:disabled {
        background-color: var(--branding-primary-200);
        border-color: var(--branding-primary-200);
        color: var(--grey-800);
    }

    &.dropdown-toggle:focus {
        box-shadow: none !important;
    }
}

.btn-secondary {
    @include apply-btn-class('btn-secondary');
}

.btn-primary,
.btn-secondary {
    font-weight: 700 !important;
}

.btn-filter {
    background-color: var(--white);
    border: 1px solid var(--grey-100);

    &:hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25), 0px 0px 0px 0.5px var(--text-primary) !important;
    }

    &.disabled:not(.locked-filter),
    &:disabled:not(.locked-filter) {
        cursor: default;
        background-color: var(--branding-primary-200) !important;
        border-color: var(--branding-primary-200) !important;
        color: var(--branding-primary-700) !important;

        .filter-circle {
            border-color: var(--branding-primary-700) !important;
        }
    }
}

.btn-data-source {
    background-color: var(--grey-50);
    align-items: baseline;
    letter-spacing: 0;
    padding: 0.375rem 0.5rem;
    width: 92px;

    .fa-arrow-up-right-from-square {
        font-size: 10px;
    }

    &.pub-med {
        color: var(--data-source-pub-med);

        .origin-icon {
            position: relative;
            top: -1px;
        }

        &:hover:not(:disabled):hover:not(.disabled):not(.no-hover):hover:not([disabled]),
        &:not(:disabled):not(.disabled):not(.no-hover).active,
        &:not(:disabled):not(.disabled):not(.no-hover):active {
            background-color: var(--data-source-pub-med);
            color: var(--white);
        }
    }

    &.pub-ag {
        color: var(--data-source-pub-ag);

        &:hover:not(:disabled):hover:not(.disabled):not(.no-hover):hover:not([disabled]),
        &:not(:disabled):not(.disabled):not(.no-hover).active,
        &:not(:disabled):not(.disabled):not(.no-hover):active {
            background-color: var(--data-source-pub-ag);
            color: var(--white);
        }
    }

    &.view-article {
        color: var(--text-default);

        &:hover:not(:disabled):hover:not(.disabled):hover:not([disabled]),
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active {
            background-color: var(--grey-900);
            color: var(--white);
        }
    }

    &:focus {
        box-shadow: 0px 0px 0px 3px var(--grey-200) !important;
    }

    &.disabled,
    &:disabled {
        background-color: var(--grey-300);
        color: var(--grey-800);

        .fa-arrow-up-right-from-square {
            display: none;
        }
    }
}

.btn-full-width {
    flex-grow: 1;
    flex-shrink: 1;
}
