@import '../base/mixins';

.filters-articles-wrapper {
    display: flex;
    gap: 25px;
    height: 100%;

    .filters {
        flex: 0 0 220px;
        overflow: hidden;

        @include md {
            flex-basis: 250px;
        }

        .selectors {
            .selector {
                display: block;
                margin: 0 2px;
                background-color: var(--white);
                border-radius: 4px;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
                user-select: none;

                &.disabled {
                    background-color: var(--branding-primary-200) !important;
                    color: var(--grey-600);
                    cursor: not-allowed;
                }
            }

            @include organization(dmi) {
                .selector {
                    background-color: var(--grey-50);
                }
            }
        }
    }

    .articles-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;

        .active-filters {
            margin-bottom: 10px;
            background-color: var(--white);
            border-radius: 0 4px 4px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
        }

        .clear-all-notification {
            margin-bottom: 10px;
        }

        .articles-list {
            @include scroll();
            overflow-y: overlay;
            display: flex;
            flex-direction: column;
            justify-items: stretch;
            min-height: 0;
            flex: 1 1 250px;
            padding-right: 5px;
        }

        .metadata-pagination-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 15px;

            .metadata {
                padding-top: 3px;
            }
        }
    }
}

.filters-articles-wrapper,
.main-menu-custom-content {
    .btn {
        .text {
            display: none;

            @include lg {
                display: block;
            }
        }
    }
}

.article-details-modal-content {
    overflow-y: auto;
    flex: 1;
}
