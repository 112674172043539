.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.3);

    &.show {
        opacity: 1;
    }
}

.modal-content {
    box-shadow: 0px 3px 6px #00000029;
    border: none;
    border-radius: 12px;
}

.message-dialog-backdrop {
    background-color: rgba(255, 255, 255, 0.5);

    &.show {
        opacity: 1;
    }
}

.message-dialog {
    overflow: hidden !important;

    .modal-dialog {
        margin: 0 auto !important;
    }

    .modal-dialog-centered {
        min-height: 80% !important;

        &::before {
            height: 100% !important;
        }
    }
}

// TODO: Consider moving these styles to modal.component.scss.
// Currently, some modal-related styles are in the modal component while others are in this file.
// We should investigate why this is happening and, if possible, standardize how we add modal styles.
.overlay {
    top: var(--main-menu-height);
    height: calc(100% - var(--main-menu-height) - var(--footer-height));
    overflow-y: hidden !important;

    &.modal-backdrop {
        background-color: var(--grey-800);
    }

    .modal-dialog {
        max-width: none;
        padding: 20px 20px 0;
        margin-top: 0;
        height: 100%;
        max-height: none;

        .modal-content {
            height: 100%;
            border-radius: 4px 4px 0 0;
        }
    }

    lib-modal,
    .custom-modal-content {
        height: 100% !important;
    }
}
