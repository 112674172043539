.tooltip {
    &.show {
        opacity: 1;
    }

    .tooltip-inner {
        background-color: var(--white);
        color: var(--text-primary);
        box-shadow: 0px 3px 6px #00000029;
        @include font-properties(xs, 11.5px);
        text-align: left;
        word-wrap: break-word;
    }

    &.bs-tooltip-top {
        .arrow::before {
            border-top-color: var(--white);
        }
    }

    &.bs-tooltip-right {
        .arrow::before {
            border-right-color: var(--white);
        }
    }

    &.bs-tooltip-bottom {
        .tooltip-inner {
            box-shadow: 0px -2px 6px #00000029;
        }

        .arrow::before {
            border-bottom-color: var(--white);
        }
    }

    &.bs-tooltip-left {
        .arrow::before {
            border-left-color: var(--white);
        }
    }

    &.main-menu-tooltip {
        .tooltip-inner {
            @include font-properties(xs);
            background-color: var(--branding-primary-900);
            color: var(--white);
            padding: 8px 10px;
            border-radius: 0 0 4px 4px;
        }
    }
}
