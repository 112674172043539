.tabset {
    display: flex;
    flex-direction: column;
    min-width: 0;
    width: 100%;
    height: 100%;
    @include font-properties(xs);

    ul {
        &.nav-tabs {
            color: var(--text-primary);
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0.75rem;

            li {
                width: 130px;

                a {
                    position: relative;
                    text-align: center;

                    &.nav-link {
                        border: none;
                        color: var(--text-primary);

                        &::after {
                            content: ' ';
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            height: 3px;
                            width: 100%;
                            background-color: var(--branding-secondary);
                            opacity: 0.4;
                            z-index: 1;
                        }

                        &:hover {
                            &::after {
                                background-color: var(--branding-secondary);
                                opacity: 1;
                            }
                        }
                        &.active {
                            &::after {
                                background-color: var(--branding-primary);
                                opacity: 1;
                            }
                        }
                    }
                }
            }

            .dropdown-menu {
                border-radius: 10px;
                padding: 15px 3px 15px 10px;
            }
        }
    }

    .tabset-top-bar-wrapper {
        @include font-properties(xs-b);
        flex: 0;
        margin: 0 2px;

        .tabset-top-bar {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            border-bottom: 1.5px solid var(--white);
            box-shadow: 0px 1.5px 0 rgba(0, 0, 0, 0.15);
            height: 50px;

            ul {
                &.nav-tabs {
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: flex-end;
                    justify-content: flex-start;
                    padding: 0;

                    .nav-item {
                        @include font-properties(sm);
                        letter-spacing: 0.01em;
                        width: fit-content;
                        border-radius: 4px 4px 0 0;

                        &:has(.disabled) {
                            cursor: not-allowed;
                        }

                        .nav-link {
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            padding: 16px 16px 8px 16px;
                            color: var(--grey-500);

                            &.tab {
                                position: relative;

                                .beta-icon {
                                    position: absolute;
                                    top: 2px;
                                    right: 9px;
                                    border-radius: 2px;
                                    background-color: var(--grey-500);
                                    color: var(--white);
                                    padding: 2px;
                                    @include font-properties(xxs-b);
                                    font-size: 9px;
                                    line-height: 8px;
                                    letter-spacing: 0.0462em;
                                }
                            }

                            .tab-title {
                                display: none;
                            }

                            @include xl {
                                .tab-title {
                                    display: block;
                                }

                                &.tab {
                                    .beta-icon {
                                        top: 6px;
                                        right: 16px;
                                    }
                                }
                            }

                            &::after {
                                height: 0;
                            }

                            &:hover {
                                color: var(--text-default);

                                &.tab {
                                    .beta-icon {
                                        background-color: var(--grey-900);
                                    }
                                }
                            }

                            &:focus {
                                outline: none;
                            }

                            &.active {
                                background-color: var(--white);
                                color: var(--text-default);
                                font-weight: 900;
                                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

                                &.tab {
                                    .beta-icon {
                                        background-color: var(--grey-900);
                                    }
                                }

                                .fa-regular {
                                    font-weight: 900;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .tabset-main-content-wrapper {
        @include font-properties(xs-b);
        display: flex;
        height: 1px;
        flex: 1;
        position: relative;

        .tabset-content-wrapper {
            display: flex;
            flex: 1;

            &.extended-sidebar {
                padding-right: var(--sidebar-extended-width);
            }
        }
    }

    &.tabset-rounded {
        .nav-tabs {
            display: flex;
            justify-content: left;
            flex-wrap: nowrap;
            border: none;

            .nav-item {
                min-width: 0;
                width: auto;
            }

            .nav-link {
                @include font-properties(xs-b);
                display: flex;
                align-items: center;
                padding: 8px 15px;
                border-radius: 6px 6px 0 0;
                border: none;
                text-wrap: nowrap;

                &::after {
                    display: none;
                }
            }
        }
    }

    &.details-tabset {
        height: 100%;

        .tab-content {
            .tab-pane {
                height: 100%;
                display: flex;
                flex-direction: column;
                padding-right: 3px;
            }
        }

        .nav-tabs {
            max-width: var(--sidebar-extended-width);
            padding: 5px 15px 25px 25px;
            gap: 3px;

            .nav-item {
                flex: 1 0 0;
                min-width: 0;

                .nav-link {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .tab-content {
            height: 1px;
            flex: 1 1 0;
        }
    }

    // Prevents inactive tab's display:none from affecting graph/table
    // positioning calculations.

    // Example: user makes filter changes while having table tab active, then
    // switches back to graph and graph is misplaced.

    // This behavior is controlled by replacing display:none with absolute positioning and opacity
    .tab-content {
        position: relative;
        overflow: hidden;
        height: 100%;

        .tab-pane {
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            pointer-events: none;
            opacity: 0;

            &:not(.active) {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0;
                z-index: -1;
            }

            &.active {
                position: initial;
                pointer-events: auto;
                opacity: 1;
            }
        }
    }
}
