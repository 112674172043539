.category {
    width: 17px;
    height: 17px;
    font-size: 17px;
    border-radius: 50%;
    margin-left: 10px;
    background-color: var(--category-other);
    display: flex;
    justify-content: center;
    align-items: center;

    &.with-inner-circle {
        position: relative;
        background-color: transparent;

        &:not(.category-multiple-categories) {
            border: 1px solid;
        }
    }

    .inner-circle {
        font-size: 13px;
        border-radius: 50%;
        position: absolute;
        margin: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.disabled {
        background-color: var(--grey-200);
        color: var(--text-secondary) !important;
    }

    &.with-icon {
        &:not(.with-inner-circle):before,
        .inner-circle:before {
            border-radius: 50%;
            font-family: 'Font Awesome Kit';
            font-weight: 400;
            line-height: 1;
        }
    }
}

.category-organisms {
    background-color: var(--category-organisms);
    border-color: var(--category-organisms) !important;
    color: var(--category-organisms) !important;

    .inner-circle {
        background-color: var(--category-organisms);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-organisms) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e009';

                @include organization(mr-plant) {
                    content: '\e029';
                }
            }
        }
    }
}

.category-genes {
    background-color: var(--category-genes);
    border-color: var(--category-genes) !important;
    color: var(--category-genes) !important;

    .inner-circle {
        background-color: var(--category-genes);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-genes) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e011';
            }
        }
    }
}

.category-pathways {
    background-color: var(--category-pathways);
    border-color: var(--category-pathways) !important;
    color: var(--category-pathways) !important;

    .inner-circle {
        background-color: var(--category-pathways);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-pathways) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e012';
            }
        }
    }
}

.category-medical-conditions {
    background-color: var(--category-medical-conditions);
    border-color: var(--category-medical-conditions) !important;
    color: var(--category-medical-conditions) !important;

    .inner-circle {
        background-color: var(--category-medical-conditions);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
                0px 0px 0px 1.5px var(--category-medical-conditions) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e00e';
            }
        }
    }
}

.category-drugs {
    background-color: var(--category-drugs);
    border-color: var(--category-drugs) !important;
    color: var(--category-drugs) !important;

    .inner-circle {
        background-color: var(--category-drugs);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-drugs) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e00f';
            }
        }
    }
}

.category-chemicals {
    background-color: var(--category-chemicals);
    border-color: var(--category-chemicals) !important;
    color: var(--category-chemicals) !important;

    .inner-circle {
        background-color: var(--category-chemicals);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-chemicals) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e015';
            }
        }
    }
}

.category-physiology {
    background-color: var(--category-physiology);
    border-color: var(--category-physiology) !important;
    color: var(--category-physiology) !important;

    .inner-circle {
        background-color: var(--category-physiology);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
                0px 0px 0px 1.5px var(--category-physiology) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e00b';

                @include organization(mr-plant) {
                    content: '\e02b';
                }
            }
        }
    }
}

.category-anatomy {
    background-color: var(--category-anatomy);
    border-color: var(--category-anatomy) !important;
    color: var(--category-anatomy) !important;

    .inner-circle {
        background-color: var(--category-anatomy);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-anatomy) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e00c';

                @include organization(mr-plant) {
                    content: '\e026';
                }
            }
        }
    }
}

.category-food {
    background-color: var(--category-food);
    border-color: var(--category-food) !important;
    color: var(--category-food) !important;

    .inner-circle {
        background-color: var(--category-food);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-food) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e010';
            }
        }
    }
}

.category-lipids {
    background-color: var(--category-lipids);
    border-color: var(--category-lipids) !important;
    color: var(--category-lipids) !important;

    .inner-circle {
        background-color: var(--category-lipids);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-lipids) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e013';
            }
        }
    }
}

.category-peptides {
    background-color: var(--category-peptides);
    border-color: var(--category-peptides) !important;
    color: var(--category-peptides) !important;

    .inner-circle {
        background-color: var(--category-peptides);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-peptides) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e014';
            }
        }
    }
}

.category-glycans {
    background-color: var(--category-glycans);
    border-color: var(--category-glycans) !important;
    color: var(--category-glycans) !important;

    .inner-circle {
        background-color: var(--category-glycans);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-glycans) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e033';
            }
        }
    }
}

.category-plant-traits {
    background-color: var(--category-plant-traits);
    border-color: var(--category-plant-traits) !important;
    color: var(--category-plant-traits) !important;

    .inner-circle {
        background-color: var(--category-plant-traits);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
                0px 0px 0px 1.5px var(--category-plant-traits) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e02d';
            }
        }
    }
}

.category-plant-stress {
    background-color: var(--category-plant-stress);
    border-color: var(--category-plant-stress) !important;
    color: var(--category-plant-stress) !important;

    .inner-circle {
        background-color: var(--category-plant-stress);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
                0px 0px 0px 1.5px var(--category-plant-stress) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e02c';
            }
        }
    }
}

.category-agricultural-processes {
    background-color: var(--category-agricultural-processes);
    border-color: var(--category-agricultural-processes) !important;
    color: var(--category-agricultural-processes) !important;

    .inner-circle {
        background-color: var(--category-agricultural-processes);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
                0px 0px 0px 1.5px var(--category-agricultural-processes) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e025';
            }
        }
    }
}

.category-agrochemicals {
    background-color: var(--category-agrochemicals);
    border-color: var(--category-agrochemicals) !important;
    color: var(--category-agrochemicals) !important;

    .inner-circle {
        background-color: var(--category-agrochemicals);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
                0px 0px 0px 1.5px var(--category-agrochemicals) !important;
        }
    }

    &.with-icon {
        &:not(.with-inner-circle),
        .inner-circle {
            background-color: transparent !important;

            &:before {
                content: '\e024';
            }
        }
    }
}

.category-other {
    background-color: var(--category-other);
    border-color: var(--category-other) !important;
    color: var(--category-other) !important;

    .inner-circle {
        background-color: var(--category-other);
    }

    &.btn {
        &:hover:not(.no-hover):hover:not(:disabled):hover:not(.disabled):hover:not([disabled]) {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 0px 0px 1.5px var(--category-other) !important;
        }
    }
}

.category-multiple-categories {
    border-color: var(--category-other) !important;
    color: var(--category-other) !important;
    background: conic-gradient(
        from 0deg at 50% 50%,
        #a5d839 0deg,
        #25b3b9 90deg,
        #9f1e70 180deg,
        #ff1d10 270deg,
        #a5d839 360deg
    );

    .inner-circle {
        background: conic-gradient(
            from 0deg at 50% 50%,
            #a5d839 0deg,
            #25b3b9 90deg,
            #9f1e70 180deg,
            #ff1d10 270deg,
            #a5d839 360deg
        );
    }

    &.border-only {
        border-color: transparent !important;
        background-image: linear-gradient(var(--white), var(--white)),
            conic-gradient(
                from 0deg at 50% 50%,
                #9f1e70 0deg,
                #25b3b9 90deg,
                #a5d839 180deg,
                #ff1d10 270deg,
                #9f1e70 360deg
            );
        border: double 2px transparent;
        background-origin: border-box;
        background-clip: padding-box, border-box;
    }

    &.with-inner-circle:before {
        content: '';
        display: block;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        border: 1px solid var(--white);
        border-radius: 50%;
    }
}
