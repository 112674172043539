[data-initials] {
    &::before {
        content: attr(data-initials);
        color: var(--white);
        @include font-properties(xs-b);
    }
}

[data-text-color-white] {
    &::before {
        content: attr(data-text-color-white);
        color: var(--white);
        @include font-properties(xxs-b);
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: initial;
    }

    &.disabled {
        &::before {
            color: var(--text-secondary);
        }
    }
}

[data-text-color-default] {
    &::before {
        content: attr(data-text-color-default);
        @include font-properties(xxs-b);
        font-weight: 900;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: initial;
    }

    &.disabled {
        &::before {
            color: var(--text-secondary);
        }
    }
}
