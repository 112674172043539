// bootstrap spinner
.spinner-border {
    color: var(--branding-secondary);

    &.spinner-border-xs {
        width: 10px;
        height: 10px;
        border-width: 1px;
    }
}
