@import '../base/mixins';

.synonym-highlight {
    @include font-properties(xs-b);
    color: var(--text-primary);
    display: contents;
}

.co-occurrence-highlight {
    background-color: #00ffff;

    @include organization(dmi) {
        background-color: var(--grey-100);
    }
}

.reference-highlight {
    background-color: #e4d7ff;
}

.entity-highlight {
    text-decoration: underline;
    font-weight: bold;
}

.unclassified-effect-highlight {
    @include organization(dmi) {
        background-color: var(--grey-100);
    }
}

.beneficial-effect-highlight {
    @include organization(dmi) {
        background-color: var(--branding-primary-700);
        color: var(--white);
    }
}

.adverse-effect-highlight {
    @include organization(dmi) {
        background-color: var(--branding-secondary-700);
        color: var(--white);
    }
}

.mixed-effect-highlight {
    @include organization(dmi) {
        background-color: var(--branding-primary-900);
        color: var(--white);
    }
}

.search-highlight {
    background-color: #ffff00;
    color: unset;
}
