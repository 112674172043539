@import 'scss/base/mixins';
@import 'scss/media-queries/mixins';
@import 'base/functions';
@import 'base/mixins';
@import 'themes/functions';
@import 'components/auth-forms';
@import 'components/badges';
@import 'components/categories';
@import 'components/filters';
@import 'components/highlights';
@import 'components/iframes';
@import 'components/references';
@import 'components/relationship-types';
@import 'components/ngb-dropdown';
@import 'components/ngb-nav';
@import 'components/ngx-datatable';

:root {
    // branding
    --branding-primary: #{theme-color(branding, primary)};
    --branding-primary-50: #{theme-color(branding, primary-50)};
    --branding-primary-100: #{theme-color(branding, primary-100)};
    --branding-primary-200: #{theme-color(branding, primary-200)};
    --branding-primary-300: #{theme-color(branding, primary-300)};
    --branding-primary-400: #{theme-color(branding, primary-400)};
    --branding-primary-500: #{theme-color(branding, primary-500)};
    --branding-primary-600: #{theme-color(branding, primary-600)};
    --branding-primary-700: #{theme-color(branding, primary-700)};
    --branding-primary-800: #{theme-color(branding, primary-800)};
    --branding-primary-900: #{theme-color(branding, primary-900)};
    --branding-secondary: #{theme-color(branding, secondary)};
    --branding-secondary-50: #{theme-color(branding, secondary-50)};
    --branding-secondary-100: #{theme-color(branding, secondary-100)};
    --branding-secondary-200: #{theme-color(branding, secondary-200)};
    --branding-secondary-300: #{theme-color(branding, secondary-300)};
    --branding-secondary-400: #{theme-color(branding, secondary-400)};
    --branding-secondary-500: #{theme-color(branding, secondary-500)};
    --branding-secondary-600: #{theme-color(branding, secondary-600)};
    --branding-secondary-700: #{theme-color(branding, secondary-700)};
    --branding-secondary-800: #{theme-color(branding, secondary-800)};
    --branding-secondary-900: #{theme-color(branding, secondary-900)};

    // list
    --list-primary: #{color(list, primary)};
    --list-secondary: #{color(list, secondary)};
    --list-hover: #{color(list, hover)};
    --list-active: #{color(list, active)};

    // text
    --text-primary: #{color(text, primary)};
    --text-secondary: #{color(text, secondary)};

    // category
    --category-organisms: #{color(category, organisms)};
    --category-genes: #{color(category, genes)};
    --category-pathways: #{color(category, pathways)};
    --category-medical-conditions: #{color(category, medical-conditions)};
    --category-drugs: #{color(category, drugs)};
    --category-chemicals: #{color(category, chemicals)};
    --category-physiology: #{color(category, physiology)};
    --category-anatomy: #{color(category, anatomy)};
    --category-food: #{color(category, food)};
    --category-lipids: #{color(category, lipids)};
    --category-peptides: #{color(category, peptides)};
    --category-glycans: #{color(category, glycans)};
    --category-plant-traits: #{color(category, plant-traits)};
    --category-plant-stress: #{color(category, plant-stress)};
    --category-agricultural-processes: #{color(category, agricultural-processes)};
    --category-agrochemicals: #{color(category, agrochemicals)};
    --category-other: #{color(category, other)};

    // badge
    --badge-high: #{color(badge, high)};
    --badge-medium: #{color(badge, medium)};
    --badge-low: #{color(badge, low)};

    // informative
    --informative-info: #{color(informative, info)};
    --informative-success: #{color(informative, success)};
    --informative-warning: #{color(informative, warning)};
    --informative-danger: #{color(informative, danger)};
    --informative-notification: #{color(informative, notification)};

    // data-source
    --data-source-pub-ag: #{color(data-source, pub-ag)};
    --data-source-pub-med: #{color(data-source, pub-med)};

    // status
    --status-in-progress: #{color(status, in-progress)};
    --status-on-hold: #{color(status, on-hold)};
    --status-done: #{color(status, done)};

    // evidence
    --evidence-table: #{color(evidence, table)};
    --evidence-network: #{color(evidence, network)};
    --evidence-report: #{color(evidence, report)};
    --evidence-articles: #{color(evidence, articles)};
    --evidence-ai-summary: #{color(evidence, ai-summary)};
    --evidence-chart: #{color(evidence, chart)};
    --evidence-trends: #{color(evidence, trends)};

    // ingredient
    --ingredient-1-dark: #{theme-color(branding, primary-800)};
    --ingredient-1-light: #{theme-color(branding, primary-500)};
    --ingredient-2-dark: #{color(ingredient, purple-dark)};
    --ingredient-2-light: #{color(ingredient, purple-light)};
    --ingredient-3-dark: #{color(ingredient, olive-dark)};
    --ingredient-3-light: #{color(ingredient, olive-light)};
    --ingredient-4-dark: #{color(ingredient, brown-dark)};
    --ingredient-4-light: #{color(ingredient, brown-light)};
    --ingredient-5-dark: #{theme-color(branding, secondary-500)};
    --ingredient-5-light: #{theme-color(branding, secondary-300)};
    --ingredient-6-dark: #{theme-color(branding, primary-500)};
    --ingredient-6-light: #{theme-color(branding, primary-300)};

    // grey
    --grey-50: #{color(grey, 50)};
    --grey-100: #{color(grey, 100)};
    --grey-200: #{color(grey, 200)};
    --grey-300: #{color(grey, 300)};
    --grey-400: #{color(grey, 400)};
    --grey-500: #{color(grey, 500)};
    --grey-600: #{color(grey, 600)};
    --grey-700: #{color(grey, 700)};
    --grey-800: #{color(grey, 800)};
    --grey-900: #{color(grey, 900)};

    // widths
    --main-menu-width: 75px;
    --sidebar-extended-width: 305px;
    --sidebar-collapsed-width: 91px;

    // heights
    --main-menu-height: 58px;
    --footer-height: 38px;
    --network-footer-height: 60px;

    // gutters
    --menu-item-gutters: 5px;
    --exploration-paths-tables-left-gutter: 120px;

    // conversational search chat bottom bar
    --conversational-search-chat-bottom-bar-height: 86px;

    // shadows
    --subtle-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15); // TODO: replace rest occurrences with this value
}

body {
    .section-title {
        @include font-properties(normal-b, 1.25rem);
        color: var(--text-primary);
        padding-left: 11px;
    }

    .btn {
        + .btn {
            margin-left: 25px;
        }
    }

    .discovery-main-menu-custom-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        margin: 0 10px 0 15px;

        .discovery-summary-condensed {
            flex: 1;
            height: 100%;
            min-width: 0;
        }

        .export-selector-wrapper {
            max-width: fit-content;
        }
    }

    .empty-circle {
        color: var(--grey-300) !important;
        box-shadow: 0 0 0px 1px;
    }

    .strength-high {
        background-color: var(--badge-high);
    }

    .strength-medium {
        background-color: var(--badge-medium);
    }

    .strength-low {
        background-color: var(--badge-low);
    }

    .strength-white {
        background-color: var(--white);
    }

    // notebooks pages
    .page-wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .page-content-wrapper {
        height: 100%;
        width: 100%;
        min-width: 0; // to avoid overflowing inside the flex parent
        min-height: 0; // to avoid overflowing inside the flex parent
        display: flex;
        flex-direction: column;
    }

    .table-wrapper {
        height: 100%;
        width: 100%;
        min-width: 0;
        min-height: 0;
        display: flex;
        flex-direction: column;
    }

    .discovery-body,
    .profiler-body {
        display: flex;
        flex-direction: row;
        overflow: hidden;
        padding: 0;
        height: 100%;
        width: 100%;

        .search-filters-toggle-wrapper {
            display: flex;
            gap: 8px;
            align-self: center;
            width: auto;

            .filters-toggle-btn {
                @include font-properties(normal);
                padding: 0;
                width: 30px;
                height: 30px;
                color: var(--white);
                background-color: var(--branding-primary);
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25) !important;

                &.active {
                    background-color: var(--branding-primary-700);
                }

                &:hover {
                    background-color: var(--branding-primary-900);
                }
            }
        }

        .filter-toggle-wrapper {
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            padding-top: 20px;
            @include font-properties(xs);

            .report-filter {
                display: flex;
                margin-right: 10px;
                justify-content: space-between;
                align-items: baseline;
            }

            .sections-toggle-wrapper {
                display: flex;
                align-items: flex-start;

                .sections-toggle {
                    background: transparent;
                    border: none;
                    color: var(--branding-secondary);
                    @include font-properties(sm);

                    .collapse {
                        display: flex;
                        flex-direction: column;

                        .fa-solid {
                            height: 12px;
                            margin-top: -3px;
                        }
                    }

                    .expand {
                        display: flex;
                        flex-direction: column-reverse;

                        .fa-solid {
                            height: 6px;
                            margin-top: -1px;
                        }
                    }
                }
            }
        }

        .report-section-content-wrapper {
            padding-right: 15px;
        }

        .sidebar {
            margin-right: 1px;
        }
    }

    // bootstrap tooltip
    .tooltip {
        &.predicted-relationship-type-tooltip {
            opacity: 1;

            .tooltip-inner {
                @include font-properties(xs);
                color: var(--text-primary);
                padding: 14px 16px;

                .probability {
                    @include font-properties(xs-b);
                    color: var(--branding-secondary);
                }
            }
        }

        &.metapath-node-tooltip {
            .tooltip-inner {
                text-align: left;
                max-width: 50ch;
                word-break: break-all;
            }
        }
    }

    // header
    .header-button {
        line-height: 1.5;
    }

    .tag-view {
        text-decoration: underline;
    }

    .typeahead-suggestion {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .table-utilities-wrapper {
        height: 60%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .clear-all {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include font-properties(xs);
        line-height: 14px;

        .icon-wrapper {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: var(--text-primary);
            margin-left: 7px;
            position: relative;

            .fa-solid {
                position: absolute;
                top: 2px;
                left: 4px;
                color: var(--white);
                font-size: 10px;
            }
        }
    }

    .current-user-dot {
        background-color: var(--branding-secondary);
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-left: 4px;
    }

    .patents-count,
    .articles-count,
    .clickable-concentration,
    .concentrations-source {
        color: var(--category-organisms);
        text-decoration: underline;
    }

    .spinning {
        animation: spinner 1s infinite linear;
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    .show-more {
        color: var(--branding-primary);

        &:hover {
            color: var(--branding-primary-700);
        }
    }

    // Custom color utility classes
    .text {
        // Progress
        &-in-progress {
            color: var(--status-in-progress);
        }

        &-on-hold {
            color: var(--status-on-hold);
        }

        &-done {
            color: var(--status-done);
        }

        // Status
        &-private {
            color: var(--branding-primary);
        }

        &-public {
            color: var(--branding-secondary);
        }

        &-shared {
            color: var(--branding-primary-700);
        }
    }

    // Custom background utility classes
    .bg {
        // Progress
        &-in-progress {
            background-color: var(--status-in-progress);
        }

        &-on-hold {
            background-color: var(--status-on-hold);
        }

        &-done {
            background-color: var(--status-done);
        }
    }
}
