// Small tablets and large smart phones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Tablets and small desktops max
$screen-lg-max: 1004px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Desktops
$screen-sxxl-min: 1500px;
$screen-xxl-min: 1920px;

/*====== Height Breakpoints =======*/

// Tablet and small desktop min height
$screen-height-lg-max: 744px;
