// ngb-popover
.popover {
    max-width: unset;
    border: none;
    border-radius: 10px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.35);

    &.bs-popover-top {
        margin-bottom: 10px;
    }

    .popover-body {
        padding: 0;
        color: var(--text-primary);
    }

    .arrow {
        display: none;
    }
}
