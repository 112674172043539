b,
strong {
    font-weight: 700;
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.user-select-none {
    user-select: none;
}

.full-width {
    width: 100% !important;
}

.full-height {
    height: 100%;
}

.scroll {
    @include scroll();
}

.no-transparency {
    opacity: 1;
}

.no-background {
    background: initial !important;
}

.no-border {
    border: none !important;
}

.white-space-pre-line {
    white-space: pre-line;
}

.font-weight-semi-bold {
    font-weight: 600;
}

.hidden {
    visibility: hidden;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-white {
    color: var(--white);
}

.text-inherit {
    color: inherit;

    &:hover {
        color: inherit;
    }
}

.text-justified {
    text-align: justify;
}

.vertical-text {
    writing-mode: tb-rl;
    transform: rotate(-180deg);
}

.break-words {
    word-break: break-all;
}
