@import '../base/mixins';

label,
.label,
.col-form-label {
    @include font-properties(normal-l);
}

.form-group {
    label {
        @include font-properties(sm);
        color: var(--text-primary);
        margin-bottom: 2px;
        letter-spacing: normal;
    }

    .form-control {
        border-radius: 10px;
        border: 1px solid var(--grey-300);
        color: var(--text-primary);
        padding: 20px 10px;
        background-color: var(--white);
        @include font-properties(sm);

        &:empty::before {
            color: var(--text-primary);
            font-weight: 400;
            cursor: text;
        }

        &.colored-background {
            background-color: var(--grey-50);

            &:empty::before {
                color: var(--grey-700);
            }
        }

        &:focus {
            box-shadow: none;
        }

        &:disabled {
            background-color: var(--grey-100);
            cursor: not-allowed;
        }

        &.title {
            @include font-properties(title);
        }

        &.ng-invalid {
            &.ng-touched {
                border: 1px solid var(--informative-danger);

                &.ng-pristine {
                    border: 1px solid var(--grey-300);
                }
            }
        }
    }

    &.ng-invalid {
        &.ng-touched {
            .error {
                @include font-properties(xs);
                font-style: italic;

                span {
                    margin-top: 7px;
                    .error-icon {
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}

select {
    appearance: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
    background-size: 12px;
    background-position: 95% center;
    background-repeat: no-repeat;
    padding-right: 30px;
    cursor: pointer;

    &.form-control {
        height: 52px;
        padding: 10px 10px !important;
    }
}

.form-check {
    margin: -6px 0 25px 0;

    .form-check-label {
        @include font-properties(sm);
        color: var(--text-secondary);
    }
}

.form-check-sm {
    margin-bottom: 1rem;

    .form-check-label {
        @include font-properties(xs-l);
    }
}

.form-navigation-link {
    color: var(--branding-secondary);
    @include font-properties(sm);

    &:hover {
        color: var(--branding-secondary-900);
    }
}

.faded-placeholder {
    input::placeholder,
    textarea::placeholder {
        color: var(--grey-300);
    }
}
