@import '../base/mixins';

.relationship-group,
.effect {
    @include font-properties(sm);
    cursor: text !important;
    color: var(--white);
    margin-left: 0 !important;
    padding: 8px;

    &:hover {
        color: var(--white);
    }
}

.relationship-group-prevents,
.effect-beneficial {
    background-color: var(--branding-secondary-900);

    @include organization(dmi) {
        background-color: var(--branding-primary-900);
    }
}

.effect {
    &.effect-beneficial-color {
        color: var(--branding-secondary-900);

        @include organization(dmi) {
            color: var(--branding-primary-900);
        }
    }
}

.relationship-group-causes,
.effect-adverse {
    background-color: var(--branding-primary-900);

    @include organization(dmi) {
        background-color: var(--branding-secondary-900);
    }
}

.effect {
    &.effect-adverse-color {
        color: var(--branding-primary-900);

        @include organization(dmi) {
            color: var(--branding-secondary-900);
        }
    }
}

.relationship-group-other,
.effect-unclassified {
    background-color: var(--branding-primary-600);

    @include organization(dmi) {
        background-color: var(--branding-secondary-600);
    }
}

.effect {
    &.effect-unclassified-color {
        color: var(--branding-primary-600);

        @include organization(dmi) {
            color: var(--branding-secondary-600);
        }
    }
}

.predicted-relationship-type {
    @include font-properties(xs-b);
    color: var(--branding-secondary);

    &.underlined {
        border-bottom: 1px dotted var(--grey-300);
    }
}
