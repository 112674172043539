.link {
    @include font-properties(sm);
    color: var(--text-primary);
    text-decoration: underline;

    &.text-xs {
        @include font-properties(xs);
    }

    &:hover {
        color: var(--grey-800);
    }
}

.branding-link {
    color: var(--branding-secondary-700);
    text-decoration: underline;

    &:hover {
        color: var(--branding-secondary-400);
    }
}
