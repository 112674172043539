@import 'scss/media-queries/mixins';

.auth-layout-container {
    margin: 0 auto;

    .form {
        width: 80%;

        @include md {
            width: 60%;
        }

        @include lg {
            width: 45%;
        }

        @include xl {
            width: 40%;
        }

        @include sxxl {
            width: 35%;
        }
    }
}

.auth-form-content {
    width: 100%;

    lib-decorated-input {
        width: 100%;

        ::placeholder {
            color: var(--grey-800);
            @include font-properties(sm);
        }

        .input-wrapper {
            .input-decorations-wrapper {
                .fa-solid,
                .fa-regular {
                    color: var(--text-secondary) !important;
                    font-size: 14px !important;
                }

                &.end {
                    top: 12px;
                }
            }

            .input-content {
                lib-input {
                    .form-group {
                        input {
                            &:focus {
                                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15) !important;
                            }
                        }
                    }
                }
            }
        }

        .form-group {
            margin-bottom: 0 !important;

            .form-control {
                &:focus {
                    border: 2px solid var(--branding-secondary);
                }

                &.ng-invalid {
                    &.ng-touched {
                        border: 2px solid var(--informative-warning);

                        &.ng-pristine {
                            border: 2px solid var(--white);
                        }
                    }
                }
            }

            input {
                @include font-properties(sm);
                border: 2px solid var(--white);
                border-radius: 6px;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);

                &.gutters {
                    padding-left: 50px;
                }
            }

            &.ng-invalid {
                &.ng-touched {
                    .error {
                        @include font-properties(sm);
                    }

                    .text-danger {
                        color: var(--informative-warning) !important;
                    }
                }
            }
        }

        &.target-invalid-error {
            .form-group {
                .form-control {
                    &.ng-invalid {
                        &.ng-touched {
                            border: solid var(--branding-primary);
                            border-width: 0 0 2px 0;
                        }
                    }
                }
            }
        }
    }

    .inputs {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .input-wrapper {
            width: 100%;

            .label {
                @include font-properties(sm);
                color: var(--text-primary);
                letter-spacing: 0.28px;
                line-height: 1;
                padding-bottom: 10px;
            }
        }
    }
}
